import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import ButtleBlock from "./ButtleBlock";
import CheckboxBlock from "./CheckboxBlock/CheckboxBlock";
import * as styles from "./NextLiverpool.module.scss";
import SetBlock from "./SetBlock";

const NextLiverpool = ({ successVoted }: { successVoted: () => void }) => {
  return (
    <section className={styles.NextLeverpool}>
      <div className={styles.container}>
        <div className={styles.title}>Next Liverpool match predictions</div>
        <ButtleBlock />
        <SetBlock successVoted={successVoted} />
        <CheckboxBlock  successVoted={successVoted} />
      </div>
    </section>
  );
};

export default NextLiverpool;
