import React, { useEffect, useRef, useState } from "react";
import Submit from "../../Submit";
import { Rating } from "react-simple-star-rating";
import * as styles from "./StarRating.module.scss";
import arrow from "../../../images/star/Vector.svg";
import useMatch from "../../../hooks/useMatch";
import { IMatch, OptionType } from "../../../context/match/matchReducer";
import { pollAPI } from "../../../api/api";
import { toast } from "react-toastify";
import Spinner from "../../Spinner";
import axios, { AxiosError } from "axios";
import useResults from "../../../hooks/use-results";
import { IUserVote } from "../../../context/result/resultReducer";
import HaveVotes from "../../HaveVotes";

const getPollCommunits = (match: IMatch) => {
  if (!match) {
    return { starFiled: [], pollId: undefined };
  }
  const pollCommunity = match.polls.filter(
    (item: { poll: { typePoll: string } }) => item.poll.typePoll === "community"
  );

  if (!pollCommunity.length) {
    return { starFiled: [], pollId: undefined };
  }

  const pollId = pollCommunity[0]?.poll?._id;
  const starFiled = pollCommunity[0]?.poll?.options?.map((item) => {
    return { ...item, value: "" };
  });

  return { starFiled, pollId };
};
// const getPollCommunitsVotes = (match: IMatch) => {
//   if (!matchVotes) {
//     return { starFiled: [], pollId: undefined };
//   }

//   const pollCommunity = matchVotes.polls.find((item) => item.pollInfo.typePoll === "community");
//   if (!pollCommunity) {
//     return { starFiled: [], pollId: undefined };
//   }

//   const starFiled = pollCommunity.communityAnswer.map((item: any) => {
//     return { footballer: item.footballer, starValues: item.stars };
//   });

//   return { starFiled };
// };

const getPollCommunitsVotes = (match: IMatch) => {
  if (!match) {
    return { starFistarFiledVotesled: [] };
  }
  const pollCommunity = match.polls.find(
    (item: { poll: { typePoll: string } }) => item.poll.typePoll === "community"
  );

  if (!pollCommunity?.poll.userVote?.length) {
    return { starFiledVotes: [] };
  }

  const starFiledVotes = pollCommunity?.poll?.userVote.map(
    (item: { footballer: string; stars: string }) => {
      return { footballer: item.footballer, starValues: item.stars };
    }
  );

  return { starFiledVotes };
};

const StarRating = ({ successVoted }: { successVoted: () => void }) => {
  const { previousMatchs, getPreviousMatches } = useMatch();

  const [state, setState] = useState<OptionType[]>([]);

  const pollIdRef = useRef<string | undefined>("");
  const [loading, setLoading] = useState(false);

  const { starFiledVotes } = getPollCommunitsVotes(previousMatchs[0]);

  useEffect(() => {
    if (!previousMatchs.length) {
      return;
    }
    const { starFiled, pollId } = getPollCommunits(previousMatchs[0]);

    pollIdRef.current = pollId;

    if (starFiled.length) {
      setState(starFiled);
    }
  }, []);

  const mapRatingToNum = (n: number) => {
    const res = n / 20;
    return String(res);
  };

  const handleRating = (rate: number, index: string | undefined) => {
    const newState =
      state &&
      state.map((item) => {
        if (item._id == index) {
          item.value = mapRatingToNum(rate);
        }
        return item;
      });

    setState(newState);
  };
  const submitHandler = async (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (loading) {
      return null;
    }

    const unseversData = state
      ?.filter((item) => item.value !== "")
      .map((item) => {
        return { footballer: item.title, stars: [Number(item.value)] };
      });

    const data = {
      commynityAnswers: [{ pollId: pollIdRef.current, answer: unseversData }],
    };
    try {
      setLoading(true);
      const matchId = previousMatchs[0].id;
      if (matchId) {
        const response = await pollAPI.setPollCommunity(matchId, data);
        toast.info("Successfully");
        successVoted();
        getPreviousMatches();
        // resetForm();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const err = error as AxiosError;
        // do something
        // or just re-throw the error
        // toast.error(error)

        toast.error(err.response?.data.message);
      } else {
        // do something else
        // or creating a new error
        throw new Error("different error than axios");
      }
    } finally {
      setLoading(false);
      // resetForm();
    }
  };

  const resetForm = () => {
    const newState = state.map((item) => {
      return { ...item, value: "" };
    });
    setState(newState);
  };

  const deadLine = previousMatchs[0]?.communityPollDeadline;

  if (!state.length) {
    return <p className={styles.notVotes}>no votes</p>;
  }
  return (
    <div className={styles.startRating}>
      {!starFiledVotes?.length ? (
        <form className={styles.container} onSubmit={submitHandler}>
          <div className={styles.starRow}>
            {state.length &&
              state.map((item, i) => {
                return (
                  <div className={styles.starItem} key={i}>
                    <div className={styles.text}>{item.title}</div>
                    <div className={styles.ratingWrapper}>
                      {item.value !== "" ? (
                        <div className={styles.iconBlockSelect}>
                          <span>{item.value}</span>
                        </div>
                      ) : (
                        <div className={styles.iconBlock}>
                          <img src={arrow} alt='arrow' />
                        </div>
                      )}

                      <Rating
                        // className={styles.rating}
                        fillColor='#FF4D4D'
                        onClick={(n) => handleRating(n, item._id)}
                        ratingValue={Number(item.value) * 20}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <Submit disabled={loading} deadLine={deadLine} />
        </form>
      ) : (
        <form className={styles.container}>
          <div className={styles.starRow}>
            <HaveVotes isShowSeason={false} />
            {starFiledVotes?.length &&
              starFiledVotes.map((item: any, i) => {
                return (
                  <div className={styles.starItem} key={i}>
                    <div className={styles.text}>{item.footballer}</div>
                    <div className={styles.ratingWrapper}>
                      {item.starValues !== "" ? (
                        <div className={styles.iconBlockSelect}>
                          <span>{item.starValues}</span>
                        </div>
                      ) : (
                        <div className={styles.iconBlock}>
                          <img src={arrow} alt='arrow' />
                        </div>
                      )}

                      <Rating
                        allowHover={false}
                        readonly
                        // className={styles.rating}
                        fillColor='#FF4D4D'
                        ratingValue={Number(item.starValues) * 20}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
          <Submit disabled={true} deadLine={deadLine} />
        </form>
      )}

      {loading && <Spinner />}
    </div>
  );
};

export default StarRating;
