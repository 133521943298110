// extracted by mini-css-extract-plugin
export var bodyFooter = "SetBlock-module--bodyFooter--+buSV";
export var bodyInput = "SetBlock-module--bodyInput--As7j4";
export var bodyItem = "SetBlock-module--bodyItem--QG-hX";
export var bodyMiddle = "SetBlock-module--bodyMiddle--xNWX6";
export var bodyText = "SetBlock-module--bodyText--hrtaB";
export var bodyTop = "SetBlock-module--bodyTop--cMdvF";
export var empty = "SetBlock-module--empty--X8rGo";
export var middleTitle = "SetBlock-module--middleTitle--szgzV";
export var rightBlock = "SetBlock-module--rightBlock--DvxN+";
export var select = "SetBlock-module--select--EhqNC";
export var selectBlock = "SetBlock-module--selectBlock--6t0+l";
export var selectItem = "SetBlock-module--selectItem--kDEEK";
export var setBlock = "SetBlock-module--setBlock--JyhH9";
export var setBlockBody = "SetBlock-module--setBlockBody--ZGLTy";
export var submit = "SetBlock-module--submit--a-fYC";