import React from "react";
import * as styles from "./CompetionTop.module.scss";

const CompetitionTop = () => {
  return (
    <section className={styles.competitionTop}>
      <div className={styles.container}>
        <h1 className={styles.title}>
          <span className={styles.titleLeft}>C</span>ompetitions
          <span className={styles.seasonWrapp}>
            <span className={styles.season}>
              <span className={styles.text}>Season</span>
              <span className={styles.date}> 2022/23</span>
            </span>
          </span>
          {/* <span className={styles.titelRight}></span> */}
        </h1>
        <h2 className={styles.subtitle}>
          During each football season we run competitions related to the current Liverpool games.
          Thus, before each game, Reds Club members can predict several statistical events, and
          those who guess the most during the season will receive rare collectible NFTs. Also, after
          each game, members can evaluate the actions of Liverpool players, and at the end of the
          season we will choose the best and award them.
        </h2>
      </div>
    </section>
  );
};

export default CompetitionTop;
