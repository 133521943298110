import React, { useCallback, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Layout from "../component/Layout";
import CompetitionTop from "../component/CompetitionTop";
import NextLiverpool from "../component/NextLiverpool";
import { Previous } from "../component/Previous/Previous";
import { AuthContext } from "../context/auth/authContext";
import useMatch from "../hooks/useMatch";
import Spinner from "../component/Spinner";
import useResults from "../hooks/use-results";
import InformModal from "../component/InformModal";
import useProfile from "../hooks/use-profile";

import { NoNFT } from "../component/NFTList";
import HeaderPage from "../component/Profile/HeaderPage";
import * as styles from "../styles/MyNFT.module.scss";

// markup
const Competitions = () => {
  const { isAuthentificated, userId } = useContext(AuthContext);
  const [ratingCategory, setRatingCategory] = React.useState(0);

  const {
    getNearestMatches,
    nearestMatchs,
    previousMatchs,

    isLoaded,
    getPreviousMatches,
  } = useMatch();

  const { getAllSeasonRating, statRating, lineUpRating, top1PlayerRating } = useResults();

  const { isSeasonTokenExists, isLoading: isLoadingToken } = useProfile();

  useEffect(() => {
    getNearestMatches();
    getPreviousMatches();
    getAllSeasonRating();
  }, []);

  useEffect(() => {
    if (isLoaded && !nearestMatchs.length && !previousMatchs.length) {
      setmodalTitle("No votes at the moment");
      // setmodalText(
      //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"
      // );
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [nearestMatchs, previousMatchs]);

  const selectCategory = useCallback((category: number) => {
    setRatingCategory(category);
  }, []);

  const successVoted = () => {
    setmodalTitle("Thank you for voting!");
    // setmodalText(
    //   "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud"
    // );
    setIsVisible(true);
  };

  const [modalTitle, setmodalTitle] = useState("");
  const [modalText, setmodalText] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  const toggleModal = (isVisible: boolean) => {
    setIsVisible(!isVisible);
  };

  // const isSomeRatingExists = statRating.length > 0 ||

  // if (userVotes.length) {
  //   return <AlreadyVoted />;
  // }

  return (
    <>
      <Helmet>
        <title> Competitions - Reds Club</title>
        <meta property='og:title' content='Competitions  - Reds Club' />
        <meta name='description' content='Competitions ' />
        <meta property='og:description' content='Competitions' />
      </Helmet>
      <Layout>
        <main className={`${styles.container} ${isSeasonTokenExists ? styles.competitions : " "}`}>
          <section className={!isSeasonTokenExists ? styles.myNFT : styles.competitions}>
            {isSeasonTokenExists ? (
              <>
                <>
                  <CompetitionTop />
                  {nearestMatchs.length > 0 && <NextLiverpool successVoted={successVoted} />}
                  {previousMatchs.length > 0 && <Previous successVoted={successVoted} />}
                  {/* {
                    <ResultsBlock
                      statRating={statRating}
                      top1PlayerRating={top1PlayerRating}
                      lineUpRating={lineUpRating}
                      actualUser={userId}
                      selectCategory={selectCategory}
                      activeCategory={ratingCategory}
                    />
                  } */}
                </>
              </>
            ) : (
              <div>
                <HeaderPage title='COMPETITIONS' />
                {isLoadingToken && <Spinner />}
                {!isLoadingToken && (
                  <NoNFT title='Here you can find competitions when you get membership NFT and start voting with it' />
                )}
              </div>
            )}

            {isVisible && (
              <InformModal
                toggleModal={() => toggleModal(isVisible)}
                title={modalTitle}
                contentText={modalText}
              />
            )}
          </section>
        </main>
      </Layout>
    </>
  );
};

export default Competitions;
