import React from 'react';
import ButtleBlock from '../NextLiverpool/ButtleBlock';
import Submit from '../Submit';
import * as styles from './Previous.module.scss';
import StarRating from './StarRating/StarRating';

export const Previous = ({ successVoted }: { successVoted: () => void }) => {
  return (
    <div className={styles.previous}>
      <div className={styles.container}>
        <p className={styles.title}>Previous Liverpool match players ratings</p>
        <ButtleBlock isRatings />
        <StarRating successVoted={successVoted}/>

        {/* <Submit /> */}
      </div>
    </div>
  );
};
