import React from 'react';
import Countdown from 'react-countdown';
import Button from '../common/Button';
import Deadline from '../NextLiverpool/DeadlineBlock';
import * as styles from './Submit.module.scss';

const Submit = ({ deadLine, disabled }: { deadLine: Date; disabled: boolean }) => {
  return (
    <div className={styles.wrapp}>
      <div className={styles.empty}> </div>
      <div className={styles.submit}>
        <Button disabled={disabled} type='submit'>
          Submit
        </Button>
      </div>
      <div>
        <Countdown renderer={Deadline} date={new Date(deadLine)} />
      </div>
    </div>
  );
};

export default Submit;
