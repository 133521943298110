import React from 'react';
import * as styles from './DeadlineBlock.module.scss';

type IDeadline = {
  days: number;
  hours: number;
  minutes: number;
  seconds?: number;
  completed: boolean;
};

const Deadline = ({ days, hours, minutes, seconds, completed }: IDeadline) => {
  return (
    <div className={styles.wrapp}>
      <div className={styles.Deadline}>
        <div className={styles.textTop}>Voting deadline</div>
        <div className={styles.timerBlock}>
          <div className={styles.digital}>{days}d</div>
          <div className={styles.dots}>:</div>
          <div className={styles.digital}>{hours}h</div>
          <div className={styles.dots}>:</div>
          <div className={styles.digital}>{minutes}m</div>
        </div>
      </div>
      <div className={styles.textBottom}>match kick off</div>
    </div>
  );
};

export default Deadline;
