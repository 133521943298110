// extracted by mini-css-extract-plugin
export var container = "StarRating-module--container--K3tvf";
export var iconBlock = "StarRating-module--iconBlock--RjF4u";
export var iconBlockSelect = "StarRating-module--iconBlockSelect--B+mPK";
export var notVotes = "StarRating-module--notVotes--PWgWg";
export var rating = "StarRating-module--rating--TiWk7";
export var ratingWrapper = "StarRating-module--ratingWrapper--iPtcR";
export var starItem = "StarRating-module--starItem--VfhFz";
export var starRow = "StarRating-module--starRow--AI4kJ";
export var startRating = "StarRating-module--startRating--n1IMX";
export var text = "StarRating-module--text--OLn3n";