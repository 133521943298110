import React, { useEffect, useRef, useState } from "react";
import Button from "../../common/Button";
import Deadline from "../DeadlineBlock";

import Select from "react-dropdown-select";
import * as styles from "./SetBlock.module.scss";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import useMatch from "../../../hooks/useMatch";
import { IPoll, OptionType } from "../../../context/match/matchReducer";
import Spinner from "../../Spinner";

import { pollAPI } from "../../../api/api";
import {
  getPollTypeBase,
  getPollTypeBaseCompetition,
  getPollUnswerFiled,
  ITextFieldTypeComp,
} from "../../../lib/helpers";

import HaveVotes from "../../HaveVotes";

const SetBlock = ({ successVoted }: { successVoted: () => void }) => {
  const { nearestMatchs, getNearestMatches, isLoading } = useMatch();

  const [loading, setLoading] = useState(false);
  const { textField: textFileldVotes, selectField: selectFieldVotes } = getPollUnswerFiled(
    nearestMatchs[0]
  );

  const { textField, selectField } = getPollTypeBaseCompetition(nearestMatchs[0]);

  const [values, setValues] = useState<ITextFieldTypeComp[] | null>(null);

  const [option1, setOption1] = useState<IPoll[]>([]);

  const selectOptions = useRef<OptionType[]>([]);

  const deadlineBasePolls = nearestMatchs[0]?.baseDeadline;

  // const data = [
  //   { text: 'LFC goals in the game', name: 'goalGame', value: 0, id: 0 },
  //   { text: 'LFC goals in the 1st half', name: 'goal1Half', value: 0, id: 1 },
  //   { text: 'LFC goals in the 2nd half', name: 'goal2Half', value: 0, id: 2 },
  //   { text: 'LFC shots on target', name: 'shotsTarget', value: 0, id: 3 },
  //   { text: 'LFC corners', name: 'cornes', value: 0, id: 4 },
  //   { text: 'LFC yellow cards', name: 'yellowCard', id: 5, value: 0 },
  // ];

  useEffect(() => {
    if (textField.length || selectField.length) {
      setValues(textField);
      setOption1(selectField);
    }
  }, [nearestMatchs.length]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: any) => {
    const { name, value } = e.target;

    if (+value < 0) {
      return;
    }

    const newValues =
      values &&
      values.map((item) => {
        if (item.id === id) {
          item.value = value;
        }
        return item;
      });
    setValues(newValues);
  };

  const submitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const answerSelect = selectOptions.current
      .map((item) => {
        return item.values;
      })
      .filter((item) => item !== undefined);
    const selectPollId = selectField[0]?.poll._id;

    if (answerSelect.length === 0) {
      toast.info(`field must have at least 1 item`);
      return;
    }

    // if (answerSelect.length < selectOptions.current.length) {
    //   toast.info(`need to choose ${selectOptions.current.length}  of players`);
    //   return;
    // }

    const dataSelect = { pollId: selectPollId, answer: answerSelect };

    const answers =
      values &&
      values.map((item) => {
        return { pollId: item.id, answer: [item.value] };
      });
    answers?.push(dataSelect);
    const data = { answers };

    try {
      setLoading(true);
      const matchId = nearestMatchs[0].id;
      if (matchId) {
        const response = await pollAPI.setPollBase(matchId, data);
        getNearestMatches();
        toast.info("Successfully");
        successVoted();
      }
    } catch (e) {
      console.log("e!!!!!!!!!!!!!!!!!!!!!", e);
      const error = e?.response?.data?.message;

      toast.error(error || "Something went wrong!");
      return;
    } finally {
      setLoading(false);
    }
  };

  const selectHandler = (e: { name: string; id: string }[]) => {
    const newOpation = option1[0].poll.options.map((item) => {
      if (item._id === e[0].id) {
        item.values = e[0].name;
      }

      return item;
    });

    selectOptions.current = newOpation;
  };

  if (textFileldVotes?.length || selectFieldVotes?.length) {
    return (
      <div className={styles.setBlock}>
        <HaveVotes />
        <form onSubmit={submitHandler}>
          <div className={styles.setBlockBody}>
            <div className={styles.bodyTop}>
              {textFileldVotes?.length > 0 &&
                textFileldVotes.map((item, i) => {
                  return (
                    <div className={styles.bodyItem} key={i}>
                      <div className={styles.bodyText}>{item.title}</div>
                      <input
                        readOnly
                        className={styles.bodyInput}
                        type='number'
                        value={item?.answer[0] || ""}
                        name={item.title}
                      />
                    </div>
                  );
                })}
            </div>
            {selectFieldVotes?.length > 0 && (
              <div className={styles.bodyMiddle}>
                {selectFieldVotes?.map((selectedPoll, index) => {
                  console.log("selectedPoll", selectedPoll);
                  return (
                    <div key={index}>
                      <div className={styles.middleTitle}>{selectedPoll.title}</div>
                      <div className={styles.selectBlock}>
                        <div className={styles.selectItem} key={index}>
                          <input
                            className={styles.bodyInput}
                            type='string'
                            readOnly
                            value={selectedPoll?.answer[0] || ""}
                            name={selectedPoll?.title}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className={styles.bodyFooter}>
              <div className={styles.empty}> </div>
              <div className={styles.submit}>
                {/* <Button type='submit' disabled={loading}>
                  Submit
                </Button> */}
              </div>

              <div className={styles.rightBlock}>
                <Countdown renderer={Deadline} date={new Date(deadlineBasePolls)} />
              </div>
            </div>
          </div>
        </form>
        {loading && <Spinner />}
      </div>
    );
  }

  return (
    <div className={styles.setBlock}>
      <form onSubmit={submitHandler}>
        <div className={styles.setBlockBody}>
          <div className={styles.bodyTop}>
            {values &&
              values.map((item, i) => {
                return (
                  <div className={styles.bodyItem} key={i}>
                    <div className={styles.bodyText}>{item.title}</div>
                    <input
                      onChange={(e) => handleInputChange(e, item.id)}
                      className={styles.bodyInput}
                      type='number'
                      value={item.value}
                      name={item.title}
                    />
                  </div>
                );
              })}
          </div>
          {option1.length > 0 && (
            <div className={styles.bodyMiddle}>
              {option1.map((selectedPoll, index) => {
                console.log("selectedPoll", selectedPoll);
                const title = selectedPoll?.poll?.options[0]?.title;
                return (
                  <div key={`${selectedPoll.poll.id}_${index}`}>
                    <div className={styles.middleTitle}>{title}</div>
                    <div className={styles.selectBlock}>
                      {option1[0].poll.options.map((item) => {
                        const options = item?.selectValues?.map((name, i) => {
                          return { name, id: item._id };
                        });
                        return (
                          <div className={styles.selectItem} key={item._id}>
                            <Select
                              color='black'
                              // values={options.filter((opt) => 0 == opt.id)}
                              labelField={"name"}
                              valueField={"name"}
                              options={options}
                              className={styles.select}
                              onChange={selectHandler}
                              values={[]}
                            />
                          </div>
                        );
                      })}
                      {/* <div className={styles.selectItem}>
                  <Select
                    values={options.filter((opt) => 1 == opt.id)}
                    labelField={'name'}
                    valueField={'name'}
                    options={options}
                    className={styles.select}
                    onChange={setOption1}
                  />
                </div>
                <div className={styles.selectItem}>
                  <Select
                    values={options.filter((opt) => 1 == opt.id)}
                    labelField={'name'}
                    valueField={'name'}
                    options={options}
                    className={styles.select}
                    onChange={setOption1}
                  />
                </div>
                <div className={styles.selectItem}>
                  <Select
                    values={options.filter((opt) => 1 == opt.id)}
                    labelField={'name'}
                    valueField={'name'}
                    options={options}
                    className={styles.select}
                    onChange={setOption1}
                  />
                </div> */}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <div className={styles.bodyFooter}>
            <div className={styles.empty}> </div>
            <div className={styles.submit}>
              <Button type='submit' disabled={loading}>
                Submit
              </Button>
            </div>

            <div className={styles.rightBlock}>
              <Countdown renderer={Deadline} date={new Date(deadlineBasePolls)} />
            </div>
          </div>
        </div>
      </form>
      {loading && <Spinner />}
    </div>
  );
};

export default SetBlock;
