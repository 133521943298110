import React from "react";
import { FC } from "react";
import * as styles from "./HaveVotes.module.scss";

type HaveVotesProps = {
  isShowSeason?: boolean;
};

const HaveVotes: FC<HaveVotesProps> = ({ isShowSeason }) => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.competitionBlock}>
          {isShowSeason && (
            <div className={styles.season}>
              <div>SEASON</div>
              <div>2021/22</div>
            </div>
          )}

          <div className={styles.title}>you have voted!</div>
        </div>
      </div>
    </div>
  );
};

export default HaveVotes;
