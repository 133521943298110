// extracted by mini-css-extract-plugin
export var ButtleContent = "ButtleBlock-module--ButtleContent--ETyBE";
export var battle = "ButtleBlock-module--battle--GkHdB";
export var battleBlock = "ButtleBlock-module--battleBlock--DNRES";
export var battleImage = "ButtleBlock-module--battleImage--fFfFA";
export var battleRow = "ButtleBlock-module--battleRow--yNj1P";
export var black = "ButtleBlock-module--black--8wU9a";
export var buttleTime = "ButtleBlock-module--buttleTime--Hleor";
export var red = "ButtleBlock-module--red--559pH";
export var score = "ButtleBlock-module--score--vfg6j";
export var timetext = "ButtleBlock-module--timetext--hRUNt";