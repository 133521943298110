import axios, { AxiosError } from "axios";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { toast } from "react-toastify";
import { DataAanswersType, pollAPI } from "../../../api/api";

import useMatch from "../../../hooks/useMatch";
import Button from "../../common/Button";
import HaveVotes from "../../HaveVotes";
import Spinner from "../../Spinner";
import Deadline from "../DeadlineBlock";
import * as styles from "./CheckboxBlock.module.scss";
import CustomCheackBox from "./CustomCheackBox";

const CheckboxBlock = ({ successVoted }: { successVoted: () => void }) => {
  const { nearestMatchs, getNearestMatches } = useMatch();

  const answer = nearestMatchs[0]?.polls.find((item) => {
    return item.poll.typePoll === "lineUp";
  })?.poll?.userVote;

  const deadlinePolls = nearestMatchs[0]?.lineUpDeadline;

  const players = nearestMatchs[0]?.polls[0]?.poll?.options;
  const pollId = nearestMatchs[0]?.polls[0].poll._id;

  const playersWithChecked = players?.map((item) => {
    return { ...item, checked: false };
  });

  const [state, setState] = useState(playersWithChecked);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (playersWithChecked) {
      setState(playersWithChecked);
    }
  }, [players]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, id: any) => {
    const { checked } = e.target;
    const newState = state.map((item) => {
      if (item._id === id) {
        item.checked = checked;
      }

      return item;
    });

    setState(newState);
  };

  const handlerSubmit = async () => {
    const data = state.filter((item) => item.checked === true).map((item) => item.title);
    if (!data.length) {
      toast.error("choose some field");
      return;
    }

    const matchId = nearestMatchs[0].id;
    const dataForm: DataAanswersType = {
      answers: [{ pollId, answer: data }],
    };
    if (matchId) {
      try {
        setLoading(true);
        const responce = await pollAPI.setPoll(matchId, dataForm);
        getNearestMatches();
        toast.info("Successfully");
        successVoted();
      } catch (error) {
        console.log("error", error);
        if (axios.isAxiosError(error)) {
          const err = error as AxiosError;
          // do something
          // or just re-throw the error
          // toast.error(error)
          toast.error(err.response?.data.message);
        }
      } finally {
        setLoading(false);
        // resetForm();
        // getNearestMatches();
      }
    }
  };

  // const resetForm = () => {
  //   const newState = state.map((item) => {
  //     return { ...item, checked: false };
  //   });
  //   setState(newState);
  // };

  if (answer?.length) {
    return (
      <div className={styles.checkboxBlock}>
        <HaveVotes />
        <div className={styles.container}>
          <h3 className={styles.title}>Guess the lineup for the game</h3>
          <div className={styles.row}>
            {players &&
              players.map((item, i) => {
                return (
                  <div className={styles.chackBoxItem} key={i}>
                    <CustomCheackBox
                      // handleInputChange={handleInputChange}
                      id={item._id}
                      checked={answer?.includes(item.title)}
                      name={item.title}
                    />
                  </div>
                );
              })}
          </div>
          <div className={styles.bodyFooter}>
            <div className={styles.empty}> </div>
            <div className={styles.submit}>
              {/* <Button disabled={loading} onClick={handlerSubmit}>
                Submit
              </Button> */}
            </div>
            <div className={styles.rightBlock}>
              <Countdown renderer={Deadline} date={new Date(deadlinePolls)} />
            </div>
          </div>
        </div>
        {loading && <Spinner />}
      </div>
    );
  }

  return (
    <div className={styles.checkboxBlock}>
      <div className={styles.container}>
        <h3 className={styles.title}>Guess the lineup for the game</h3>
        <div className={styles.row}>
          {state &&
            state.map((item, i) => {
              return (
                <div className={styles.chackBoxItem} key={i}>
                  <CustomCheackBox
                    handleInputChange={handleInputChange}
                    id={item._id}
                    checked={item.checked}
                    name={item.title}
                  />
                </div>
              );
            })}
        </div>
        <div className={styles.bodyFooter}>
          <div className={styles.empty}> </div>
          <div className={styles.submit}>
            <Button disabled={loading} onClick={handlerSubmit}>
              Submit
            </Button>
          </div>
          <div className={styles.rightBlock}>
            <Countdown renderer={Deadline} date={new Date(deadlinePolls)} />
          </div>
        </div>
      </div>
      {loading && <Spinner />}
    </div>
  );
};

export default CheckboxBlock;
