import React from "react";
import * as styles from "./CustomCheackBox.module.scss";

const CustomCheackBox = ({
  name,
  checked,
  handleInputChange = () => {},
  id,
}: {
  name: string;
  checked?: boolean;
  handleInputChange?: (e: any, id: any) => void;
  id: string | undefined;
}) => {
  return (
    <div>
      <input
        type='checkbox'
        className='custom-checkbox'
        id={id}
        name={name}
        value={name}
        checked={checked}
        onChange={(e) => handleInputChange(e, id)}
      />
      <label htmlFor={id}>{name}</label>
    </div>
  );
};

export default CustomCheackBox;
